<template>
  <section class="biometric" :class="{ 'is-mobile': isMobile }">
    <button v-if="isMobile" class="close-button" @click="close">
      <StIcon name="cross-large" :size="20" />
    </button>
    <StIcon name="face-id" class="face" :size="isMobile ? 36 : 56" />
    <h2 class="title">{{ t('verification.biometricHeader') }}</h2>
    <p class="text">{{ t('verification.biometricInfo') }}</p>
    <StButton
      class="button"
      type="primary"
      :size="isMobile ? 'l' : 'xl'"
      :to="{ query: { modal: 'sumSubVerification' } }"
    >
      {{ t('verification.biometricButton') }}
    </StButton>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = usePlatform()
const router = useRouter()

function close() {
  router.replace({ query: {} })
}
</script>

<style scoped>
.biometric {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 480px;
  padding: var(--spacing-500) var(--spacing-600);

  background: radial-gradient(
      50% 50% at 50% 0%,
      rgb(255 255 255 / 12%) 0%,
      rgb(17 17 25 / 12%) 100%
    ),
    var(--background-base, #111118);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.title {
  margin: 0 0 var(--spacing-100) 0;
  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
  text-align: center;
}

.text {
  margin: 0 0 var(--spacing-300) 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.face {
  margin-bottom: var(--spacing-300);
  background-color: var(--text-primary);
}

.button {
  width: 100%;
}

.is-mobile {
  &.biometric {
    width: 100%;
  }

  .close-button {
    all: unset;

    position: absolute;
    top: 24px;
    right: 4px;

    padding: var(--spacing-125);

    line-height: 0;
  }

  .face {
    margin-bottom: var(--spacing-250);
  }

  .title {
    font: var(--mobile-title-1-semibold);
  }

  .text {
    margin-bottom: var(--spacing-200);
    font: var(--mobile-text-content-regular);
  }
}
</style>
